@import '@styles/variables.less';

:root,
:root[theme-mode='light'] {
  --td-brand-color-1: @color-brand1;
  --td-brand-color-2: @color-brand2;
  --td-brand-color-3: @color-brand3;
  --td-brand-color-4: @color-brand4;
  --td-brand-color-5: @color-brand5;
  --td-brand-color-6: @color-brand6;
  --td-brand-color-7: @color-brand7;
  --td-brand-color-8: @color-brand8;
  --td-brand-color-9: @color-brand9;
  --td-brand-color-10: @color-brand10;
  --td-warning-color-1: @color-warning1;
  --td-warning-color-2: @color-warning2;
  --td-warning-color-3: @color-warning3;
  --td-warning-color-4: @color-warning4;
  --td-warning-color-5: @color-warning5;
  --td-warning-color-6: @color-warning6;
  --td-warning-color-7: @color-warning7;
  --td-warning-color-8: @color-warning8;
  --td-warning-color-9: @color-warning9;
  --td-warning-color-10: @color-warning10;
  --td-error-color-1: @color-error1;
  --td-error-color-2: @color-error2;
  --td-error-color-3: @color-error3;
  --td-error-color-4: @color-error4;
  --td-error-color-5: @color-error5;
  --td-error-color-6: @color-error6;
  --td-error-color-7: @color-error7;
  --td-error-color-8: @color-error8;
  --td-error-color-9: @color-error9;
  --td-error-color-10: @color-error10;
  --td-success-color-1: @color-success1;
  --td-success-color-2: @color-success2;
  --td-success-color-3: @color-success3;
  --td-success-color-4: @color-success4;
  --td-success-color-5: @color-success5;
  --td-success-color-6: @color-success6;
  --td-success-color-7: @color-success7;
  --td-success-color-8: @color-success8;
  --td-success-color-9: @color-success9;
  --td-success-color-10: @color-success10;
  --td-gray-color-1: @color-gray1;
  --td-gray-color-2: @color-gray2;
  --td-gray-color-3: @color-gray3;
  --td-gray-color-4: @color-gray4;
  --td-gray-color-5: @color-gray5;
  --td-gray-color-6: @color-gray6;
  --td-gray-color-7: @color-gray7;
  --td-gray-color-8: @color-gray8;
  --td-gray-color-9: @color-gray9;
  --td-gray-color-10: @color-gray10;
  --td-gray-color-11: @color-gray11;
  --td-gray-color-12: @color-gray12;
  --td-gray-color-13: @color-gray13;
  --td-gray-color-14: @color-gray14;

  // 文字 & 图标 颜色
  --td-font-white-1: @color-font-white1;
  --td-font-white-2: @color-font-white2;
  --td-font-white-3: @color-font-white3;
  --td-font-white-4: @color-font-white4;
  --td-font-gray-1: @color-font-gray1;
  --td-font-gray-2: @color-font-gray2;
  --td-font-gray-3: @color-font-gray3;
  --td-font-gray-4: @color-font-gray4;

  // 基础颜色
  --td-brand-color: @color-brand7;     // 色彩-品牌-可操作
  --td-warning-color: @color-warning5; // 色彩-功能-警告
  --td-error-color: @color-error6;     // 色彩-功能-失败
  --td-success-color: @color-success5; // 色彩-功能-成功

  // 基础颜色的扩展 用于 hover / 聚焦 / 禁用 / 点击 等状态
  --td-brand-color-hover: @color-brand6;     // hover态
  --td-brand-color-focus: @color-brand2;     // focus态，包括鼠标和键盘
  --td-brand-color-active: @color-brand9;    // 点击态
  --td-brand-color-disabled: @color-brand3;  // 禁用态
  --td-brand-color-light: @color-brand1;     // 浅色的选中态

  // 警告色扩展
  --td-warning-color-hover: @color-warning4;
  --td-warning-color-focus: @color-warning2;
  --td-warning-color-active: @color-warning6;
  --td-warning-color-disabled: @color-warning3;
  --td-warning-color-light: @color-warning1;

  // 失败/错误色扩展
  --td-error-color-hover: @color-error5;
  --td-error-color-focus: @color-error2;
  --td-error-color-active: @color-error7;
  --td-error-color-disabled: @color-error3;
  --td-error-color-light: @color-error1;

  // 成功色扩展
  --td-success-color-hover: @color-success4;
  --td-success-color-focus: @color-success2;
  --td-success-color-active: @color-success6;
  --td-success-color-disabled: @color-success3;
  --td-success-color-light: @color-success1;

  // 遮罩
  --td-mask-active: rgba(0, 0, 0, 60%); // 遮罩-弹出
  --td-mask-disabled: rgba(255, 255, 255, 60%); // 遮罩-禁用

  // 背景色
  --td-bg-color-page: @color-blue-gray2;                        // 色彩 - page
  --td-bg-color-container: @color-white1;                                // 色彩 - 容器
  --td-bg-color-container-hover: @color-blue-gray2;             // 色彩 - 容器 - hover
  --td-bg-color-container-active: @color-blue-gray3;            // 色彩 - 容器 - active
  --td-bg-color-container-select: @color-white1;                         // 色彩 - 容器 - select
  --td-bg-color-secondarycontainer: @color-blue-gray1;          // 色彩 - 次级容器
  --td-bg-color-secondarycontainer-hover: @color-blue-gray2;    // 色彩 - 次级容器 - hover
  --td-bg-color-secondarycontainer-active: @color-blue-gray4;   // 色彩 - 次级容器 - active
  --td-bg-color-component: @color-gray3;                   // 色彩 - 组件
  --td-bg-color-component-hover: @color-blue-gray2;             // 色彩 - 组件 - hover
  --td-bg-color-component-active: @color-blue-gray3;            // 色彩 - 组件 - active
  --td-bg-color-component-disabled: @color-gray1;          // 色彩 - 组件 - disabled

  // 特殊组件背景色，目前只用于 button、input 组件多主题场景，浅色主题下固定为白色，深色主题下为 transparent 适配背景颜色
  --td-bg-color-specialcomponent: #fff;

  // 文本颜色
  --td-text-color-primary: @color-font-gray1;      // 色彩-文字-主要
  --td-text-color-secondary: @color-font-gray2;    // 色彩-文字-次要
  --td-text-color-placeholder: @color-font-gray3;  // 色彩-文字-占位符/说明
  --td-text-color-disabled: @color-font-gray4;    // 色彩-文字-禁用
  --td-text-color-anti: @color-font-white1;                      // 色彩-文字-反色
  --td-text-color-brand: @color-brand7;           // 色彩-文字-品牌
  --td-text-color-link: @color-brand7;            // 色彩-文字-链接

  // 分割线
  --td-border-level-1-color: @color-gray2;
  --td-component-stroke: @color-gray2;
  // 边框
  --td-border-level-2-color: @color-gray4;
  --td-component-border: @color-gray4;

  // 基础/下层 投影 hover 使用的组件包括：表格 /
  --td-shadow-1: @shadow-basic;
  // 中层投影 下拉 使用的组件包括：下拉菜单 / 气泡确认框 / 选择器 /
  --td-shadow-2: @shadow-middle;
  // 上层投影（警示/弹窗）使用的组件包括：全局提示 / 消息通知
  --td-shadow-3: @shadow-top;

  // 内投影 用于弹窗类组件（气泡确认框 / 全局提示 / 消息通知）的内描边
  --td-shadow-inset-top: inset 0 .5px 0 #dcdcdc;
  --td-shadow-inset-right: inset .5px 0 0 #dcdcdc;
  --td-shadow-inset-bottom: inset 0 -.5px 0 #dcdcdc;
  --td-shadow-inset-left: inset -.5px 0 0 #dcdcdc;

  // table 特定阴影
  --td-table-shadow-color: rgba(0, 0, 0, 8%);

  // 滚动条颜色
  --td-scrollbar-color: rgba(0, 0, 0, 10%);

  // 圆角
  --td-radius-small: @border-radius-small;
  --td-radius-default: @border-radius;
  --td-radius-medium: @border-radius-medium;
  --td-radius-large: @border-radius-large;
  --td-radius-extraLarge: @border-radius-extraLarge;
  --td-radius-round: @border-radius-round;
  --td-radius-circle: @border-radius-circle;
}

:root,
:root[lang='zh'] {
  --td-font-family: PingFang SC, Microsoft YaHei, Arial Regular;
  --td-font-family-mediumy: PingFang SC, Microsoft YaHei, Arial Regular;
  // 保持与tdesign字体统一
  font-family: PingFang SC, Microsoft YaHei, Arial Regular;
} 

:root[lang='en'] {
  // Arial, Helvetica字体优先
  --td-font-family: Arial, Helvetica, Arial Regular, PingFang SC, Microsoft YaHei;
  --td-font-family-mediumy: Arial, Helvetica, Arial Regular, PingFang SC, Microsoft YaHei;
  font-family: Arial, Helvetica, Arial Regular, PingFang SC, Microsoft YaHei;
} 


body {
  .t-dialog {
    @paddingLeft: 32px;
    @paddingTop: 28px;
    @paddingRight: 32px;
    @paddingBottom: 32px;

    &.t-dialog--default {
      padding: 0;
    }

    > .t-dialog__header {
      padding: @paddingTop @paddingRight 24px @paddingLeft ;
    }

    &__body {
      padding-left: @paddingLeft;
      padding-right: @paddingRight;
    }

    &__footer {
      padding: @paddingTop @paddingRight @paddingBottom @paddingLeft;
      box-sizing: border-box;

      .t-button--theme-default {
        border: 0;
        background-color: @color-blue-gray2;

        &:hover {
          color: @color-font-gray1;
          background-color: @color-blue-gray3;
        }
      }
    }
  }

  // 针对加单位的数字输入框
  .t-addon {
    .t-input-number {
      width: 100%;
    }

    &.t-addon--append .t-input-number.t-is-controls-right .t-input-number__decrease {
      border-bottom-right-radius: 0;
    }

    &.t-addon--append .t-input-number.t-is-controls-right .t-input-number__increase {
      border-top-right-radius: 0;
    }

    .t-input-number .t-input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .t-popup__reference {
    display: contents;
  }

  // 步骤条 宽度 颜色不对
  .t-steps--horizontal.t-steps--default-anchor {
    .t-steps-item:not(:last-child) {
      .t-steps-item__title {
        &::after {
          transform: translateY(0);
          border-bottom-color: @color-gray4;
        }
      }
    }

    .t-steps-item--finish:not(:last-child) {
      .t-steps-item__title:after {
        border-bottom-color: var(--td-brand-color);
      }
    }
  }

  .t-image-viewer-preview-image {
    z-index: 9999;
  }

  // Drawer组件字体颜色
  .t-drawer__content-wrapper {
    color: inherit;
  }
}
