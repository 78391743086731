@import '@src/styles/atom.less';

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1280px;
  height: 72px;
  background-color: @color-white1;
  border-bottom: 1px solid @color-gray3;
  .px-16;

  .navLeft {
    .flex-row;
    .space-x-32;
    align-self: stretch;

    .logo {
      align-self: stretch;

      a {
        display: block;
        width: 138px;
        
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
      }

      .zhH1 {
        a {
          background-image: url(../../../assets/images/logo.png);
        }
      }

      .enH1 {
        a {
          background-image: url(../../../assets/images/logo-en.png);
        }
      }
    }

    .menus {
      .flex-c;
      align-self: stretch;
      
      .menuActive {
        border-bottom: 2px solid @color-brand7;
      }

      a {
        text-decoration: none;
        .text-4;
        .font-gray2;
        .py-8;
      }
    }
  }


  .navRight {
    .flex-row;
    .space-x-32;
    align-self: stretch;

    .menus {
      .flex-c;
      align-self: stretch;
    }

    .avatar {
      .flex-row;
      .flex-c;
      .space-x-4;
    }
  }
}
