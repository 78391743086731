.container {
  display: flex;
  font-size: 14px;

  .button {
    padding: 0 5px;
    cursor: pointer;
  }

  .activeLanguage {
    color: #1946ba;
    font-weight: bold;
  }
  
}
