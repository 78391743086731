@import '@src/styles/atom.less';

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'TencentSans-W3';
  src: url('../../assets/fonts/TencentSans-W3.woff');
}

html,
body,
.root {
  height: 100%;
  width: 100%;
  .scrollbar;
}

.root {
  display: flex;
  flex-direction: column;
}

.root > :nth-child(2) {
  flex: 1;
}

a {
  color: @color-brand7;
  text-decoration: none;
}
