@import '@src/styles/atom.less';

.footer {
  .text-5();
  color: @color-blue-gray5;

  p {
    text-align: center;
  }
}
