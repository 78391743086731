@import '@src/styles/fonts.less';


.footer {
  min-width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: @color-blue-gray2;
  padding: @spacer32 0;
  .text-5();
  color: @color-blue-gray5;
  
}
