// color
@color-white1: #FFFFFF;

@color-gray1: #F3F3F3;
@color-gray2: #EEEEEE;
@color-gray3: #E7E7E7;
@color-gray4: #DCDCDC;
@color-gray5: #C5C5C5;
@color-gray6: #A6A6A6;
@color-gray7: #8B8B8B;
@color-gray8: #777777;
@color-gray9: #5E5E5E;
@color-gray10: #4B4B4B;
@color-gray11: #383838;
@color-gray12: #2C2C2C;
@color-gray13: #242424;
@color-gray14: #181818;

@color-blue-gray1: #F7F8FA;
@color-blue-gray2: #F0F2F5;
@color-blue-gray3: #E3E6EB;
@color-blue-gray4: #D6DBE3;
@color-blue-gray5: #BCC4D0;
@color-blue-gray6: #97A3B7;
@color-blue-gray7: #7787A2;
@color-blue-gray8: #5F7292;
@color-blue-gray9: #4B5B76;
@color-blue-gray10: #193753;
@color-blue-gray11: #0F2E4B;
@color-blue-gray12: #002140;
@color-blue-gray13: #1C222B;
@color-blue-gray14: #13161B;

@color-brand1: #f2f3ff;
@color-brand2: #d9e1ff;
@color-brand3: #b5c7ff;
@color-brand4: #8eabff;
@color-brand5: #618dff;
@color-brand6: #366ef4;
@color-brand7: #0052d9;
@color-brand8: #003cab;
@color-brand9: #002a7c;
@color-brand10: #001a57;
@color-brand-bg: linear-gradient(90deg, #307AF2 0%, #0057FE 100%);

@color-font-white1: rgba(255, 255, 255, .9);
@color-font-white2: rgba(255, 255, 255, .55);
@color-font-white3: rgba(255, 255, 255, .35);
@color-font-white4: rgba(255, 255, 255, .22);

@color-font-gray1: rgba(0, 0, 0, .9);
@color-font-gray2: rgba(0, 0, 0, .6);
@color-font-gray3: rgba(0, 0, 0, .4);
@color-font-gray4: rgba(0, 0, 0, .26);

@color-error1: #FDECEE;
@color-error2: #F9D7D9;
@color-error3: #F8B9BE;
@color-error4: #F78D94;
@color-error5: #F36D78;
@color-error6: #E34D59;
@color-error7: #C9353F;
@color-error8: #B11F26;
@color-error9: #951114;
@color-error10: #680506;

@color-warning1: #FEF3E6;
@color-warning2: #F9E0C7;
@color-warning3: #F7C797;
@color-warning4: #F2995F;
@color-warning5: #ED7B2F;
@color-warning6: #D35A21;
@color-warning7: #BA431B;
@color-warning8: #9E3610;
@color-warning9: #842B0B;
@color-warning10: #5A1907;

@color-success1: #E8F8F2;
@color-success2: #BCEBDC;
@color-success3: #85DBBE;
@color-success4: #48C79C;
@color-success5: #00A870;
@color-success6: #078D5C;
@color-success7: #067945;
@color-success8: #056334;
@color-success9: #044F2A;
@color-success10: #033017;

// font
@font-size1: 36px;
@font-size2: 20px;
@font-size3: 16px;
@font-size4: 14px;
@font-size5: 12px;
@font-size6: 10px;

@font-line-height1: 36px;
@font-line-height2: 28px;
@font-line-height3: 24px;
@font-line-height4: 22px;
@font-line-height5: 20px;
@font-line-height6: 16px;

@font-normal: 400;
@font-bold: 600;

// border-radius
@border-radius-small: 0px;
@border-radius: 0px;
@border-radius-medium: 0px;
@border-radius-large: 0px;
@border-radius-extraLarge: 0px;
@border-radius-round: 999px;
@border-radius-circle: 50%;

// box-shadow
@shadow-basic: 0px 1px 10px rgba(0, 0, 0, .05), 0px 2px 5px rgba(0, 0, 0, .08), 0px 4px 4px -1px rgba(0, 0, 0, .12);
@shadow-middle: 0px 3px 14px 2px rgba(0, 0, 0, .05), 0px 8px 10px 1px rgba(0, 0, 0, .06), 0px 5px 5px -3px rgba(0, 0, 0, .1);
@shadow-top: 0px 6px 30px 5px rgba(0, 0, 0, .05), 0px 16px 24px 2px rgba(0, 0, 0, .04), 0px 8px 10px -5px rgba(0, 0, 0, .08);

// Spacer
@spacer0: 0px; // 间距-0
@spacer4: 4px; // 间距-4
@spacer8: 8px; // 间距-8
@spacer12: 12px; // 间距-12
@spacer16: 16px; // 间距-16
@spacer24: 24px; // 间距-24
@spacer32: 32px; // 间距-32
@spacer40: 40px; // 间距-大-40
@spacer48: 48px; // 间距-大-48
@spacer64: 64px; // 间距-大-64
@spacer96: 96px; // 间距-大-96
@spacer160: 160px; // 间距-大-160

// 统一层级关系,tdesign min:500
@z-index1: 1;
@z-index2: 10;
@z-index3: 20;
@z-index4: 30;
@z-index5: 100;
@z-index6: 200;
@z-index7: 300;
@z-index8: 400;

// 屏幕宽度
@screen-min: 992px;
@screen-max: 1440px;
